// libs
import React from 'react';
import camelCase from 'lodash/camelCase';

import useScreen from '@lyearn/core/hooks/useScreen';
import { useTranslation } from '@lyearn/i18n';
import { cn } from '@lyearn/style';
import { Stack } from '@lyearn/ui';
import { Image } from '@/components/Image';
import { BackgroundType, useAppConfig } from '@/hooks/useAppConfig';
import { BackgroundLayoutTypeEnum } from '@/types/schema';

import { backgroundImage, containerMaxWidth } from './style';

const LayoutContainer: React.FC<
  React.PropsWithChildren<{ className?: string; background?: BackgroundType }>
> = (props) => {
  const { children, className, background } = props;
  const { isMobile } = useScreen();
  const { t } = useTranslation();
  const {
    site: { logoUrl, name },
  } = useAppConfig();
  const layout = background?.layout;
  const backgroundCSS = cn(
    'bg-center bg-no-repeat bg-cover',
    backgroundImage(background?.imageUrl),
  );

  return (
    <Stack
      className="flex-1"
      direction={
        layout === camelCase(BackgroundLayoutTypeEnum.LeftAligned) ? 'row-reverse' : 'row'
      }>
      <Stack
        className={cn(
          [
            camelCase(BackgroundLayoutTypeEnum.RightAligned),
            camelCase(BackgroundLayoutTypeEnum.LeftAligned),
          ].includes(layout!)
            ? cn('flex-1 xs:flex-none', backgroundCSS)
            : 'flex-none',
        )}
      />
      <Stack
        className={cn(
          'relative flex-1',
          layout === camelCase(BackgroundLayoutTypeEnum.CentreAligned) && !isMobile
            ? backgroundCSS
            : '',
        )}
        component="main">
        <Stack
          alignItems="center"
          className={cn(
            'transformToCenter absolute w-full rounded bg-bg-primary',
            isMobile ? 'h-full p-10 ' : containerMaxWidth,
            className,
          )}
          direction="col"
          justifyContent="center">
          <Image
            useImg
            alt={t('Common:LogoOfImage', { imageAlt: name })}
            className="mb-24 h-32"
            resizeMode="contain"
            src={logoUrl}
          />
          {children}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default LayoutContainer;
