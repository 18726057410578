import React, { useCallback, useMemo, useState } from 'react';
import capitalize from 'lodash/capitalize';

import useScreen from '@lyearn/core/hooks/useScreen';
import { useTranslation } from '@lyearn/i18n';
import { Lock2Outline } from '@lyearn/icons';
import { cn } from '@lyearn/style';
import { Button, Input, Typography, TypographyVariants } from '@lyearn/ui';
import type { ButtonProps } from '@lyearn/ui/src/Buttons/Button/types';
import { Image } from '@/components/Image';
import { AuthConfigMethodMeta } from '@/hooks/useAppConfig';
import useNavigation from '@/hooks/useNavigation';
import AuthRoutes from '@/pages/auth/routes';

import useSocialAuth, { SocialType } from '../../hooks/useSocialAuth';
import type { SocialLoginProps } from './types';
import { AuthType } from './types';

type LoginButtonsProps = Pick<SocialLoginProps, 'currentProvider' | 'setCurrentProvider'> & {
  Icon?: React.ReactElement;
  meta?: string;
  title: string;
  strategyUrl: string;
  provider: SocialType;
  authType: string;
  buttonClassName?: string;
  typographyVariant?: TypographyVariants;
  className?: string;
} & Pick<ButtonProps, 'size'>;

const LoginButtons: React.FC<LoginButtonsProps> = ({
  Icon,
  authType,
  meta,
  strategyUrl,
  title,
  provider,
  currentProvider,
  setCurrentProvider,
  size = 'large',
  buttonClassName,
  typographyVariant,
  className,
}) => {
  const { t } = useTranslation();
  const navigation = useNavigation();
  const { isMobile } = useScreen();
  const requiredField = (JSON.parse(meta || '{}') as AuthConfigMethodMeta)?.required;
  const [metaState, setMetaState] = useState<Record<string, string> | undefined>(
    () => requiredField?.reduce((acc: any, field: { id: any }) => ({ ...acc, [field.id]: '' }), {}),
  );

  const { onLogin } = useSocialAuth(authType, provider, strategyUrl);
  const metaFields = useMemo(
    () =>
      requiredField?.map((field, index) => (
        <Input
          key={field.id}
          fullWidth
          required
          autoFocus={index === 0}
          className="mb-16"
          placeholder={t(field.placeholder)}
          size="large"
          type={field.type}
          onChangeText={(value) => setMetaState((oldMeta) => ({ ...oldMeta, [field.id]: value }))}
        />
      )),
    [requiredField, t],
  );

  const onClick = useCallback(
    (event: any) => {
      event.preventDefault();
      if (!metaFields) {
        if (provider === SocialType.LOCAL) {
          navigation.replace(
            authType === AuthType.SignIn ? AuthRoutes.EmailLogin() : AuthRoutes.EmailSignup(),
          );
        } else {
          onLogin();
        }
      }

      if (currentProvider !== provider) {
        return setCurrentProvider?.(provider);
      }
      onLogin(undefined, undefined, metaState);
    },
    [
      authType,
      currentProvider,
      metaFields,
      metaState,
      navigation,
      onLogin,
      provider,
      setCurrentProvider,
    ],
  );

  return (
    <form className={cn('flex w-full flex-col px-28', className)} onSubmit={onClick}>
      {currentProvider === provider ? metaFields : null}
      <Button
        fullWidth
        alignment="extreme"
        appearance="secondary"
        aria-label={title}
        className={cn('mb-16', buttonClassName)}
        size={size}
        startIcon={Icon}
        type="submit"
        variant="outlined">
        <Typography className="xs:ml-30" variant={typographyVariant}>
          {title}
        </Typography>
      </Button>
    </form>
  );
};

const LoginButtonsContainer: React.FC<
  SocialLoginProps &
    Pick<LoginButtonsProps, 'className' | 'buttonClassName' | 'typographyVariant' | 'size'>
> = ({
  authType,
  currentProvider,
  setCurrentProvider,
  authMethods,
  size,
  buttonClassName,
  typographyVariant,
  className,
}) => {
  const { t } = useTranslation();

  return (
    <>
      {authMethods?.map((authMethod) => {
        return (
          <LoginButtons
            key={authMethod.provider}
            Icon={
              authMethod.icon ? (
                <Image
                  alt={`${authMethod.provider} Logo`}
                  className="flex"
                  height={24}
                  src={authMethod.icon}
                  width={24}
                />
              ) : (
                <Lock2Outline className="h-20 w-20" fillColorToken="icon-active" />
              )
            }
            authType={authType}
            buttonClassName={buttonClassName}
            className={className}
            currentProvider={currentProvider}
            meta={authMethod.meta}
            provider={authMethod.provider as SocialType}
            setCurrentProvider={setCurrentProvider}
            size={size}
            strategyUrl={authMethod.webStrategyUrl}
            title={
              authType === AuthType.SignIn
                ? t(authMethod.signInLabel, {
                    provider: capitalize(authMethod.provider),
                  })
                : t(authMethod.signUpLabel, {
                    provider: capitalize(authMethod.provider),
                  })
            }
            typographyVariant={typographyVariant}
          />
        );
      })}
    </>
  );
};
export default LoginButtonsContainer;
