import { css } from '@emotion/css';
import config from 'config';

function getRandomImg() {
  return randomImages[Math.floor(Math.random() * randomImages.length)];
}

const randomImages = [
  `${config.cdn_domain}/assets/images/login-layout/login-layout-1.jpeg`,
  `${config.cdn_domain}/assets/images/login-layout/login-layout-2.jpeg`,
  `${config.cdn_domain}/assets/images/login-layout/login-layout-3.jpeg`,
];

const randomImage = getRandomImg();

export const containerMaxWidth = css`
  max-width: 60rem;
  padding: 8.8rem 8.2rem;
`;

export const backgroundImage = (url?: string) => css`
  background-image: url(${url || randomImage});
`;
