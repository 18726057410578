import { useCallback } from 'react';

import useAnalytics from '@/components/Analytics/hooks/useAnalytics';
import useParams from '@/hooks/useParams';

import useSearchParams from '../../../../hooks/useSearchParam';
import { AuthType } from '../../components/LoginButtons/types';
import getPathNameAndSearch from '../../utils/getPathNameAndSearch';

function useSocialAuth(authType: string, provider: string, strategyUrl?: string) {
  const { redirectTo } = useParams<{ redirectTo?: string }>();
  const { track } = useAnalytics();
  const inviteToken = useSearchParams('inviteToken');

  const onLogin = useCallback(
    async (idToken?: any, profile?: any, metaFields?: any) => {
      const redirectUrl = new URL(window.location.origin);
      const { pathname, search } = getPathNameAndSearch(redirectTo);
      redirectUrl.pathname = pathname;
      redirectUrl.search = search;
      if (authType === AuthType.SignUp) {
        redirectUrl.pathname = '/about-yourself';
      }
      const encodedRedirectUrl = encodeURIComponent(redirectUrl.toString());

      let url = new URL(
        `${strategyUrl}?authType=${authType}&redirectUrl=${encodedRedirectUrl}&provider=${provider}&inviteToken=${inviteToken}`,
        window.location.origin,
      );

      metaFields &&
        Object.entries(metaFields).map(([key, value]) => {
          url.searchParams.append(key, `${value}`);
        });

      window.location.href = url.toString();

      track('Logged In', { method: provider });
    },
    [redirectTo, strategyUrl, authType, provider, track, inviteToken],
  );

  return { onLogin };
}

export default useSocialAuth;
