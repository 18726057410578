// libs
import React, { useEffect, useState } from 'react';

import { useTranslation } from '@lyearn/i18n';
import { cn } from '@lyearn/style';
import { Stack, Typography } from '@lyearn/ui';
import { Link } from '@/components/Link';
import useAuthConfig from '@/hooks/useAuthConfig';
import useNavigation from '@/hooks/useNavigation';
import SocialLogins from '@/modules/auth/components/LoginButtons';
import useLoginUrl from '@/modules/auth/hooks/useLoginUrl';
import useQueryParamErrorCode from '@/modules/auth/hooks/useQueryParamErrorCode';
import AuthRoutes from '@/pages/auth/routes';

import LayoutContainer from '../../components/LayoutContainer';
import { AuthType } from '../../components/LoginButtons/types';

export const SignupLink = ({
  signUpPath,
}: {
  signUpPath: React.ComponentProps<typeof Link>['to'];
}) => {
  const { t } = useTranslation();

  return (
    <Stack alignItems="center" direction="row">
      <Typography className="inline me-4" color="text-text-secondary" variant="body-short-01">
        {t('Auth:NoAccount')}
      </Typography>
      <Link className="flex" to={signUpPath}>
        <Typography className="inline" variant="heading-xs">
          {t('Auth:signUp')}
        </Typography>
      </Link>
    </Stack>
  );
};

const Login = () => {
  const { t } = useTranslation();
  const navigation = useNavigation();
  const [currentProvider, setCurrentProvider] = useState('');
  const { shouldRender } = useLoginUrl();
  const { signUpAllowed, title, background, authMethods } = useAuthConfig();
  useQueryParamErrorCode();

  const signUpPath = { pathname: AuthRoutes.Signup(), query: navigation.query };

  useEffect(() => {
    navigation.prefetch?.(AuthRoutes.EmailLogin());
    navigation.prefetch?.(AuthRoutes.EmailSignup());
  }, [navigation]);

  if (!shouldRender) {
    return null;
  }

  return (
    <LayoutContainer background={background}>
      <Stack alignItems="center" className="mb-40 text-center" direction="col">
        <Typography as="h1" className={cn(signUpAllowed ? 'mb-12' : '')} variant="display-xxl">
          {title || t('Auth:welcomeBack')}
        </Typography>
        {signUpAllowed ? <SignupLink signUpPath={signUpPath} /> : null}
      </Stack>
      <SocialLogins
        authMethods={authMethods}
        authType={AuthType.SignIn}
        currentProvider={currentProvider}
        setCurrentProvider={setCurrentProvider}
      />
    </LayoutContainer>
  );
};

export default Login;
