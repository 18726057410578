import config from 'config';

import { TFunction } from '@lyearn/i18n';
import { AppleLogo, EmailOutline, Google, Lock2Outline } from '@lyearn/icons';
import { SamlTypeEnum, SupportedStrategyMethodTypeEnum } from '@/types/schema';

export enum ProviderType {
  Google = 'GOOGLE',
  Apple = 'APPLE',
  Email = 'LOCAL',
  SAML = 'SAML',
}

export const MethodConfigs = {
  [ProviderType.Google]: {
    provider: ProviderType.Google,
    icon: `${config.cdn_domain}/assets/images/google.svg`,
    signInLabel: 'Auth:continueWithGoogle',
    signUpLabel: 'Auth:continueWithGoogle',
    type: SupportedStrategyMethodTypeEnum.Oauth,
    hidden: false,
  },
  [ProviderType.Apple]: {
    provider: 'APPLE',
    icon: `${config.cdn_domain}/assets/images/apple-negative.svg`,
    signInLabel: 'Auth:continueWithApple',
    signUpLabel: 'Auth:continueWithApple',
    type: SupportedStrategyMethodTypeEnum.Oauth,
    hidden: false,
  },
  [ProviderType.Email]: {
    provider: 'LOCAL',
    icon: `${config.cdn_domain}/assets/images/email.svg`,
    signInLabel: 'Auth:signInWithEmail',
    signUpLabel: 'Auth:signUpWithEmail',
    type: SupportedStrategyMethodTypeEnum.Credential,
    hidden: false,
  },
  [ProviderType.SAML]: {
    provider: '',
    icon: '',
    signInLabel: 'Auth:continueWithSAML',
    signUpLabel: 'Auth:continueWithSAML',
    type: SupportedStrategyMethodTypeEnum.Sso,
    hidden: false,
    samlConfigs: [
      {
        IDPEntityId: '',
        samlType: SamlTypeEnum.HttpPost,
        idpCertificate: '',
        syncAttributes: {
          name: true,
        },
        SPInitiated: false,
        samlSSOUrl: '',
      },
    ],
  },
};

export const DefaultMethods = [
  MethodConfigs[ProviderType.Google],
  MethodConfigs[ProviderType.Email],
];

export const getAuthMethods = (t: TFunction) => [
  {
    Icon: Google,
    provider: ProviderType.Google,
    title: t('AuthenticationSettings:Sidebar.AddMethod.Provider.Google.Title'),
    description: t('AuthenticationSettings:Sidebar.AddMethod.Provider.Google.Description'),
  },
  {
    Icon: EmailOutline,
    provider: ProviderType.Email,
    title: t('AuthenticationSettings:Sidebar.AddMethod.Provider.Email.Title'),
    description: t('AuthenticationSettings:Sidebar.AddMethod.Provider.Email.Description'),
  },
  {
    Icon: AppleLogo,
    provider: ProviderType.Apple,
    title: t('AuthenticationSettings:Sidebar.AddMethod.Provider.Apple.Title'),
    description: t('AuthenticationSettings:Sidebar.AddMethod.Provider.Apple.Description'),
  },
  {
    Icon: Lock2Outline,
    provider: ProviderType.SAML,
    title: t('AuthenticationSettings:Sidebar.AddMethod.Provider.SAML.Title'),
    description: t('AuthenticationSettings:Sidebar.AddMethod.Provider.SAML.Description'),
  },
];
