import { useMemo } from 'react';

import { isIDPMethod } from '@/modules/authentication-settings/utils';
import { StrategyMethod } from '@/types/schema';

import { useAppConfig } from './useAppConfig';

const useAuthConfig = () => {
  const appConfig = useAppConfig();
  const { site, authConfig } = appConfig;
  const authPageUrl = site.loginUrl || authConfig.authPageUrl;
  const { strategyMethods, signUpAllowed, background, title, strategyMethodsListing } = authConfig;

  const sortedStrategyMethods = useMemo(
    () =>
      strategyMethodsListing?.length
        ? strategyMethods
            ?.slice()
            .sort(
              (a, b) =>
                strategyMethodsListing.indexOf(a.provider) -
                strategyMethodsListing.indexOf(b.provider),
            )
        : strategyMethods,
    [strategyMethods, strategyMethodsListing],
  );

  return useMemo(() => {
    const localSignUpConfig = sortedStrategyMethods?.filter(
      (strategy) => strategy.provider === 'LOCAL' && !strategy.hidden,
    )[0];

    return {
      authPageUrl,
      signUpAllowed: signUpAllowed,
      localSignIn: sortedStrategyMethods?.filter((strategy) => strategy.provider === 'LOCAL')[0],
      localSignUp: localSignUpConfig,
      // We are filtering out the hidden methods which can be set through
      // authentication set-up page.
      authMethods: sortedStrategyMethods?.filter(
        (strategy) => !strategy.hidden && !isIDPMethod(strategy as StrategyMethod),
      ),
      title: title,
      background: background,
    };
  }, [authPageUrl, background, signUpAllowed, sortedStrategyMethods, title]);
};

export default useAuthConfig;
