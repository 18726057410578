import { useEffect } from 'react';

import { useTranslation } from '@lyearn/i18n';
import { useSnackbar } from '@lyearn/ui';
import useNavigation from '@/hooks/useNavigation';

import { getErrorMessage } from '../utils/errorMessage';

const ERROR_CODE_QUERY_PARAM = 'errorCode';

export default function useQueryParamErrorCode() {
  const { t } = useTranslation(['Auth']);
  const { showErrorSnackbar } = useSnackbar();
  const navigation = useNavigation();

  const queryParams = navigation.query;

  const { [ERROR_CODE_QUERY_PARAM]: errorCode, ...restQueryParam } = queryParams;

  const error = (errorCode as string) || '';

  useEffect(() => {
    const getI18nErrorMessage = getErrorMessage(t);
    const errorMessage = getI18nErrorMessage(error);

    if (error && errorMessage) {
      showErrorSnackbar({ message: errorMessage });
      navigation.replace(navigation.pathname, { appendQuery: false, query: { ...restQueryParam } });
    }
  }, [error, navigation, restQueryParam, showErrorSnackbar, t]);
}
