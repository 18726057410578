import { SSOStrategyType } from '@/hooks/useAppConfig';

export enum AuthType {
  // these enum values are used in backend. make sure it is handled there if changing here
  SignIn = 'login',
  SignUp = 'signUp',
}
export interface SocialLoginProps {
  authType: AuthType;
  currentProvider?: string;
  setCurrentProvider?: (provider: string) => void;
  authMethods?: SSOStrategyType[];
}
