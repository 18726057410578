import { useLayoutEffect } from 'react';

import useAuthConfig from '@/hooks/useAuthConfig';
import useParams from '@/hooks/useParams';

function useLoginUrl() {
  const { redirectTo } = useParams<{ redirectTo?: string }>();
  const { authPageUrl } = useAuthConfig();

  // avoid recursion if login url is explicitly pointing to lyearn instance
  const isSameUrl = window.location.href.indexOf(authPageUrl!) === 0;
  const shouldRender = !authPageUrl || isSameUrl;

  useLayoutEffect(() => {
    if (shouldRender) {
      return;
    }

    try {
      // This is to prevent crash in native
      const newUrl = new URL(authPageUrl);
      if (redirectTo) {
        // fromUrl will be pathname. get url with domain using URL instance
        const redirectUrl = new URL(redirectTo, window.location.origin);

        newUrl.searchParams.set('redirectTo', redirectUrl.toString());
      }

      window.location?.replace(newUrl.toString());
    } catch (e) {}
  }, [isSameUrl, authPageUrl, redirectTo, shouldRender]);

  return { shouldRender };
}

export default useLoginUrl;
