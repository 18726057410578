import {
  BackgroundLayoutTypeEnum,
  StrategyMethod,
  SupportedStrategyMethodTypeEnum,
  WorkspaceType,
} from '@/types/schema';

import { DefaultMethods } from '../views/AuthenticationSettings/components/AuthenticationSettingsSidebar/components/AddMethods/utils';

export const DEFAULT_VALUE = {
  title: '',
  image: {
    url: '',
    name: '',
  },
  layout: BackgroundLayoutTypeEnum.NoBackground,
  methods: DefaultMethods as StrategyMethod[],
  signUpAllowed: false,
  restrictedSignUpSettings: {
    restrictedSignUp: false,
    whitelistedSignUpDomains: [],
  },
  spCertificate: '',
  acsUrl: '',
  spEntityId: '',
};

export const isIDPMethod = (method: StrategyMethod) => {
  return (
    method.type === SupportedStrategyMethodTypeEnum.Sso &&
    method?.samlConfigs?.[0] &&
    !method.samlConfigs[0].SPInitiated
  );
};

const adaptMethods = (methods?: StrategyMethod[], strategyMethodsListing?: string[]) => {
  const updatedMethod = methods?.map((method) => {
    if (
      method.type === SupportedStrategyMethodTypeEnum.Sso &&
      method.signInLabel !== 'Auth:continueWithSAML'
    ) {
      return {
        ...method,
        signInLabel: 'Auth:continueWithSAML',
        signUpLabel: 'Auth:continueWithSAML',
      };
    }
    return method;
  });
  return strategyMethodsListing
    ? updatedMethod
        ?.slice()
        .sort(
          (a, b) =>
            strategyMethodsListing.indexOf(a.provider) - strategyMethodsListing.indexOf(b.provider),
        )
    : updatedMethod;
};

export const adaptAuthDetails = (details?: WorkspaceType) => {
  const authConfig = details?.configs?.authConfig;
  return {
    title: authConfig?.title || DEFAULT_VALUE.title,
    image: {
      url: authConfig?.background?.imageUrl || '',
      name: authConfig?.background?.imageName || '',
    },
    layout: authConfig?.background?.layout || DEFAULT_VALUE.layout,
    methods:
      adaptMethods(authConfig?.strategyMethods, authConfig?.strategyMethodsListing) ||
      DEFAULT_VALUE.methods,
    signUpAllowed: authConfig?.signUpAllowed || DEFAULT_VALUE.signUpAllowed,
    restrictedSignUpSettings: {
      restrictedSignUp:
        authConfig?.restrictedSignUp || DEFAULT_VALUE.restrictedSignUpSettings.restrictedSignUp,
      whitelistedSignUpDomains:
        details?.configs?.whitelistedSignUpDomains ||
        DEFAULT_VALUE.restrictedSignUpSettings.whitelistedSignUpDomains,
    },
    spCertificate: authConfig?.spCertificate || DEFAULT_VALUE.spCertificate,
    acsUrl: details?.acsUrl || DEFAULT_VALUE.acsUrl,
    spEntityId: details?.spEntityId || DEFAULT_VALUE.spEntityId,
  };
};
